import React from 'react'
import ReactDOM from "react-dom/client"
import './index.css'
import { BrowserRouter } from "react-router-dom"
import AppBase from './AppBase'

const root = ReactDOM.createRoot(
  document.getElementById("root")
)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppBase />
    </BrowserRouter>
  </React.StrictMode>
)
