import React, { useEffect } from 'react'
import { Grid, useMediaQuery } from '@mui/material'
import Header from './components/navigation/Header'
import SiteRouter from './components/navigation/SiteRouter'
import MobileNavBar from './components/navigation/MobileNavBar'
import './App.css'
import { useLocation } from 'react-router-dom'
import { initiateGA4 } from './components/utility/navigation'

export default function App() {
  const location = useLocation()
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search
      })
    }
    else {
      initiateGA4()
    }
  }, [location.pathname])

  return (
    <Grid container justifyContent="center">
      <Grid container flexDirection="column" alignItems="center" sx={{ maxWidth: '1980px' }}>
        <Header />
        <main>
          <SiteRouter />
        </main>
      </Grid>
      { mobile && <MobileNavBar />} 
    </Grid>
  )
}

