// Skills
// Languages
import JS from '../../img/home/skills/languages/js.png'
import CS from '../../img/home/skills/languages/c-sharp.png'
import CMD from '../../img/home/skills/languages/command.png'
import CSS from '../../img/home/skills/languages/css.png'
import HTML5 from '../../img/home/skills/languages/html-5.png'
import Python from '../../img/home/skills/languages/python.png'
import SQL from '../../img/home/skills/languages/sql.png'
// Frameworks
import Django from '../../img/home/skills/frameworks/django-icon.png'
import ASPNET from '../../img/home/skills/frameworks/asp-net.png'
import Celery from '../../img/home/skills/frameworks/celery.png'
import DotnetCore from '../../img/home/skills/frameworks/dotnet-core.png'
import DRF from '../../img/home/skills/frameworks/drf.png'
import EFC from '../../img/home/skills/frameworks/entity-framework-core.png'
import ExpJS from '../../img/home/skills/frameworks/expressjs.png'
import jQuery from '../../img/home/skills/frameworks/jquery.png'
import MaterialUI from '../../img/home/skills/frameworks/materialui.png'
import Mongoose from '../../img/home/skills/frameworks/mongoose.png'
import NodeJS from '../../img/home/skills/frameworks/nodejs.png'
import RabbitMQ from '../../img/home/skills/frameworks/rabbitmq.png'
import ReactNative from '../../img/home/skills/frameworks/react-native.png'
import ReactImg from '../../img/home/skills/frameworks/react.png'
import SASS from '../../img/home/skills/frameworks/sass.png'
import Selenium from '../../img/home/skills/frameworks/selenium.png'
import SocketIO from '../../img/home/skills/frameworks/socketio.png'
// Databases
import Postgres from '../../img/home/skills/databases/postgres.png'
import MySQL from '../../img/home/skills/databases/mysql.png'
import MongoDB from '../../img/home/skills/databases/mongodb.png'
import MySQLWorkbench from '../../img/home/skills/databases/mysql-workbench.png'
import pgAdmin from '../../img/home/skills/databases/pgadmin.png'
import DBeaver from '../../img/home/skills/databases/dbeaver.png'
// Deployment/Cloud Services
import EC2 from '../../img/home/skills/deployment/ec2.png'
import Route53 from '../../img/home/skills/deployment/r53.png'
import S3 from '../../img/home/skills/deployment/s3.png'
import ElasticBeanstalk from '../../img/home/skills/deployment/elastic-beanstalk.png'
import SQS from '../../img/home/skills/deployment/sqs.png'
import GithubActions from '../../img/home/skills/deployment/github-actions.png'
import Docker from '../../img/home/skills/deployment/docker.png'


// Contacts
import Email from '../../img/navigation/email.png'
import Github from '../../img/navigation/github.png'
import LinkedIn from '../../img/navigation/linkedin.png'
import Phone from '../../img/navigation/telephone.png'


export const links = {
    'email': 'nathanroe.dev@gmail.com',
    'phone': '(208) 954-3979',
    'linkedin': 'https://www.linkedin.com/in/nathan-zachary-roe/',
    'github': 'https://github.com/nathan-roe',
    'shortcuts': 'https://github.com/nathan-roe/shortcuts',
    'robotics': 'https://github.com/nathan-roe/face_detection',
    'projectGames': 'https://github.com/nathan-roe/discord_bots'
}

export const contacts = [
    {
        img: Github,
        link: links.github,
        handleLink: () => window.open(links.github, '_blank')
    },
    {
        img: LinkedIn,
        link: links.linkedin,
        handleLink: () => window.open(links.linkedin, '_blank')
    },
    {
        img: Email,
        link: links.email,
        handleLink: () => window.open('https://mail.google.com/mail/u/3/#inbox?compose=CllgCJZWxpzGrrKFvpStTjFNVTNsDjqXrJMrkqBGcwVdrxJTDwVgRlgfWHZjkvBZgMrDRGGhVsB', '_blank')
    },
    {
        img: Phone,
        link: links.phone
    }
]


export const skills = {
    'Languages': [
        {
            img: JS,
            text: 'JavaScript'
        },
        {
            img: Python,
            text: 'Python'
        },
        {
            img: CS,
            text: 'C#'
        },
        {
            img: CSS,
            text: 'CSS'
        },
        {
            img: HTML5,
            text: 'HTML5'
        },
        {
            img: SQL,
            text: 'SQL'
        },
        {
            img: CMD,
            text: 'Linux CLI'
        }
    ],
    'Frameworks/Libraries': [
        {
            img: Django,
            text: 'Django'
        },
        {
            img: DRF,
            text: 'Django REST Framework'
        },
        {
            img: ReactImg,
            text: 'React'
        },
        {
            img: ReactNative,
            text: 'React Native'
        },
        {
            img: ASPNET,
            text: 'ASP.NET'
        },
        {
            img: Celery,
            text: 'Celery'
        },
        {
            img: DotnetCore,
            text: '.NET Core'
        },
        {
            img: EFC,
            text: 'Entity Framework Core'
        },
        {
            img: ExpJS,
            text: 'ExpressJS'
        },
        {
            img: jQuery,
            text: 'jQuery'
        },
        {
            img: MaterialUI,
            text: 'Material UI'
        },
        {
            img: Mongoose,
            text: 'Mongoose'
        },
        {
            img: NodeJS,
            text: 'NodeJS'
        },
        {
            img: RabbitMQ,
            text: 'RabbitMQ'
        },
        {
            img: SASS,
            text: 'SASS'
        },
        {
            img: Selenium,
            text: 'Selenium'
        },
        {
            img: SocketIO,
            text: 'Socket.io'
        }
    ],
    'Databases/GUIs': [
        {
            img: Postgres,
            text: 'PostgreSQL'
        },
        {
            img: pgAdmin,
            text: 'pgAdmin'
        },
        {
            img: MySQL,
            text: 'MySQL'
        },
        {
            img: MySQLWorkbench,
            text: 'MySQL Workbench'
        },
        {
            img: MongoDB,
            text: 'MongoDB'
        },
        {
            img: DBeaver,
            text: 'DBeaver'
        }
    ],
    'Deployment/Cloud Services': [
        {
            img: EC2,
            text: 'AWS EC2'
        },
        {
            img: Route53,
            text: 'AWS Route 53'
        },
        {
            img: S3,
            text: 'AWS S3'
        },
        {
            img: ElasticBeanstalk,
            text: 'AWS Elastic Beanstalk'
        },
        {
            img: SQS,
            text: 'AWS SQS'
        },
        {
            img: GithubActions,
            text: 'Github Actions'
        },
        {
            img: Docker,
            text: 'Docker'
        }
    ]
}
