import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { Grid, Typography } from '@mui/material'
import ContactMe from '../../img/contactme/comments.png'
import AboutMe from '../../img/contactme/computer.png'
import Projects from '../../img/contactme/programming.png'
import { Link } from 'react-router-dom'


export default function MobileNavBar() {

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" sx={{
                height: '65px',
                paddingX: { xs: 0, md: '17px' },
                display: 'flex',
                alignItems: 'center',
                background: 'primary.main',
                transition: 'background .5s',
                top: 'auto',
                bottom: 0
            }}>
                <Toolbar sx={{ maxWidth: '1980px', width: '100%', height: '100%' }}>
                    <Grid container sx={{ pl: { xs: 2, md: 5 }, pr: { xs: 2, md: 10 }, '& *': { textDecoration: 'none' } }} justifyContent="space-around">
                        {
                            buttons.map(button => (
                                <Link to={button.link} sx={{ textDecoration: 'none' }}>
                                    <Grid container flexDirection="column" alignItems="center">
                                        <Box
                                            component="img"
                                            src={button.img}
                                            sx={{ height: 30, width: 30 }}
                                        />
                                        <Typography variant="caption" color="secondary">{button.text}</Typography>
                                    </Grid>
                                </Link>
                            ))
                        }
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

const buttons = [
    {
        img: AboutMe,
        text: 'About Me',
        link: 'aboutme'
    },
    {
        img: Projects,
        text: 'Projects',
        link: 'projects'
    },
    {
        img: ContactMe,
        text: 'Contact Me',
        link: 'contactme'
    }
]