import React from 'react'
import ContactMe from './ContactMe'
import MessageContext from './MessageContext'


export default function ContactMeBase() {

    return (
        <MessageContext>
            <ContactMe />
        </MessageContext>
    )
}