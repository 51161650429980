import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const Theme = createTheme({
    palette: {
        primary: {
            light: '#0d1e4f',
            main: '#0d1e4f',
        },
        secondary: {
            main: '#fff',
            light: '#0d1e4f',
        },
        gradient: 'linear-gradient(to right,rgba(255, 255, 255, 0.8) 13%,rgba(255, 255, 255, 0.84) 0,#5779077a 100%)',
        unavailable: '#969696'
    },
    typography: {
        fontFamily: ['"Varela Round"', '"Lato"', '"Oxygen"', 'sans serif'].join(','),
        textTransform: 'none',
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                h6: {
                    lineHeight: 1.5
                }
            }
        }
    }
})

export const theme = responsiveFontSizes(Theme)
// #3e457c

