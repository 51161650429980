import { Collapse } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useEffect, useState } from 'react'
import '../../static/css/code-highlighting.css'
import TextWriter from './TextWriter'

export default function CodeError({ error }) {
    const [displayError, setDisplayError] = useState(false)
    const [start, setStart] = useState(false)

    
    const handleEnd = () => {
        setDisplayError(true)
        document.addEventListener('scroll', addStartListener)
    }
    
    const handleScroll = useCallback(() => {
        if (window.scrollY > 2428) {
            setStart(true)
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])
    
    const addStartListener = useCallback(() => {
        if (window.scrollY < 2000) {
            setStart(false)
            setDisplayError(false)
            document.removeEventListener('scroll', addStartListener)
            document.addEventListener('scroll', handleScroll)
        }
    }, [handleScroll])

    useEffect(() => {
        document.addEventListener('scroll', handleScroll)
    }, [handleScroll])

    return (
        <>
            <code className='error-bg'>$ <TextWriter text='python3 education.py' onEnd={handleEnd} start={start} /></code>
            <Box sx={{ height: '200px' }}>
                <Collapse in={displayError}>
                    <code className='error-bg'>
                        {error}
                    </code>
                </Collapse>
            </Box>
        </>
    )
}