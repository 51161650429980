import { useCallback, useEffect, useState } from 'react'


export default function TextWriter({ text, start, onEnd, speed = 75 }) {

    const [displayText, setDisplayText] = useState('')

    const onEndCallback = useCallback(() => {
        onEnd()
    }, [onEnd])

    useEffect(() => {
        if (start) {
            console.log(displayText)
            setTimeout(() => {
                if (displayText?.length < text?.length) {
                    setDisplayText(dt => dt + (dt ? (text.split(dt)[1][0] || '') : text[0]))
                } else {
                    onEndCallback()
                }
            }, Math.round((Math.random() + 1) * speed))
        } else {
            setDisplayText('')
        }
    }, [start, displayText, onEndCallback, speed, text])


    return displayText
}