export const learning = `import pytz
from datetime import datetime, timedelta

class Education:

    def __init__(self) -> None:
        self.found_passion = "College"
        self.taught = True
        two_years_ago = datetime.now(tz=pytz.utc) - timedelta(days=700)
        self.joined_bootcamp = two_years_ago

        self.learned_stacks = ['C#/.NET', 'MERN', 'Python/Django']
        self.graduated_with_honors = True
        self.continuing_learning = True

    def continue_to_learn(self) -> None:
        super().grow()
        while self.continuing_learning:
            self.continue_to_learn()

education = Education()
education.continue_to_learn()
`


export const work = `import subprocess
from celery.schedules import crontab
from nathan_roe.celery import app

class WorkExperience(Education):

    def __init__(self):
        super().__init__()
        self.experience = []


    @app.task
    def update_knowledge(self, learned_material):
        self.experience.append(learned_material)
    
    @app.task
    def commit_critical_code(self, code):
        subprocess.call(['sh', './commit-code.sh', code])


    @app.on_after_finalize.connect
    def setup_periodic_tasks(self, sender, **kwargs):
        
        sender.add_periodic_task(
            crontab(minute=0, hour=0),
            self.commit_critical_code(kwargs.get('code'))
        )

        sender.add_periodic_task(
            crontab(hour='*'),
            self.update_knowledge(kwargs.get('learned_material'))
        )`
