import React from 'react'
import Prism from 'prismjs'
import '../../static/css/code-highlighting.css'
import { Box, Typography } from '@mui/material'
import CodeError from './CodeError'

class CodeHighlighter extends React.Component {
    componentDidMount() {
        Prism.highlightAll()
    }

    render() {
        return (
            <>
                <pre>
                    <code className={'language-' + this.props.language}>
                        {this.props.children}
                    </code>
                    {
                        this.props.error &&
                        <Box className='error error-bg'>
                            <Box sx={{ bgcolor: '#333', height: '30px', width: '100%' }}>
                                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', height: '100%' }}>
                                    <Box sx={{ height: '10px', width: '10px', borderRadius: '50%', bgcolor: '#999', ml: 2, mr: .5 }} />
                                    <Box sx={{ height: '10px', width: '10px', borderRadius: '50%', bgcolor: '#999', mx: .5 }} />
                                    <Box sx={{ height: '10px', width: '10px', borderRadius: '50%', bgcolor: '#999', mx: .5 }} />
                                    <Typography variant="caption" color="secondary" sx={{ ml: 1 }}>/c/nathan-roe/portfolio/education</Typography>
                                </Box>
                                <Box sx={{ flexGrow: 9 }} />
                            </Box>
                            <code className='user'>nathan-roe@portfolio</code>
                            {!this.props.mobile && <code className='terminal'>termiNate</code>}
                            <code className='dir'>~/nathan_roe/portfolio</code>
                            <br />
                            <CodeError error={this.props.error}/>
                        </Box>
                    }
                </pre>
            </>
        )
    }
}

export default CodeHighlighter