import { Box, Grid, Slide, Typography } from '@mui/material'
import React from 'react'
import useResponsive from '../utility/useResponsive'


export default function InformationSection({ img, title, content, renderContent, top = false, reverse = false }) {
    const calculateFontSize = useResponsive()


    return (
        <>
            <Box sx={{ display: { xs: 'none', sm: 'flex' }, height: 'fit-content', minHeight: '200px' }}>
                <Slide direction={reverse ? 'left' : 'right'} mountOnEnter unmountOnExit in={renderContent} timeout={1000}>
                    {/* Desktop */}
                    <Grid container flexDirection={reverse ? 'row-reverse' : 'row'} sx={{ pb: 6, pt: top ? 0 : 6 }}>
                        <Grid item xs={1}>
                            <Grid container justifyContent={reverse ? 'flex-end' : 'flex-start'}>
                                <Box
                                    component="img"
                                    src={img}
                                    sx={{ width: 150, height: 150 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={11} lg={9} sx={{ ...(reverse ? { pr: { xs: 15, lg: 10 } } : { pl: { xs: 15, lg: 10 } }) }}>
                            <Grid container flexDirection="column" justifyContent="center">
                                <Typography variant="h6" align={reverse ? 'right' : 'left'} sx={{ fontWeight: 'bold' }}>{title}</Typography>
                                <Typography variant="body1" align={reverse ? 'right' : 'left'} sx={{ mt: 2 }}>{content}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Slide>
            </Box>
            {/* Mobile */}
            <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
                <Grid container flexDirection="column" alignItems="center" sx={{ pb: 6, pt: top ? 0 : 6, display: { xs: 'flex', sm: 'none' } }}>
                    <Box
                        component="img"
                        src={img}
                        sx={{ width: '50%', height: 'auto' }}
                    />
                    <Typography align="center" variant={calculateFontSize("h4")} sx={{ fontWeight: 'bold', mb: 2 }}>{title}</Typography>
                    <Typography align="center" sx={{ px: 2 }} variant={calculateFontSize("body1")}>{content}</Typography>
                </Grid>
            </Box>
        </>
    )
}