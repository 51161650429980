import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import ApplicationFlow from '../../img/home/application-flow.png'
import SmartPhone from '../../img/home/smartphone.png'
import Server from '../../img/home/server.png'
import Branches from '../../img/home/branches.png'
import Code from '../../img/home/code.png'
import InformationSection from './InformationSection'
import Prism from 'prismjs'
import SkillScroller from './SkillScroller'
import Experience from './Experience'
import Footer from './Footer'
import useResponsive from '../utility/useResponsive'


export default function Home() {
    const [displayInfo, setDisplayInfo] = useState(false)
    const medium = useMediaQuery(theme => theme.breakpoints.down('lg'))
    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const calculateFontSize = useResponsive()

    useEffect(() => {
        Prism.highlightAll()
        if(window.innerHeight > 1000) {
            setDisplayInfo(true)
        }
    }, [])

    const updateDisplay = useCallback(() => {
        if (window.scrollY > 0) {
            setDisplayInfo(true)
        } else {
            setDisplayInfo(false)
        }
    }, [])

    useEffect(() => {
        document.addEventListener('scroll', updateDisplay)
    }, [updateDisplay])

    return (
        <>
            <Box sx={{ width: '100%', height: '250px', bgcolor: 'primary.main', position: 'relative' }}>
                <Box sx={{ position: 'absolute', height: '100%', width: '100%', overflow: 'hidden', zIndex: 1, top: 0, }}>
                    <Box
                        component="img"
                        src={Code}
                        alt="Code"
                        sx={{ position: 'absolute', opacity: .5, width: '100%', height: 'auto', minHeight: '100%', bottom: 0, filter: 'blur(3px)' }}
                    />
                </Box>
                <Grid container flexDirection="column" alignItems="center" justifyContent="center"
                    sx={{ height: '100%', pt: '65px', zIndex: 2, position: 'relative' }}>
                    <Typography variant="h3" color="secondary">Nathan Roe</Typography>
                    <Typography variant="h4" color="secondary">{'<Python '}&#8226; React &#8226; {'C#>'}</Typography>
                </Grid>
            </Box>
            <Grid container flexDirection="column" alignItems="center">
                <Grid item xs={10} xl={9} sx={{ pt: { xs: 5, lg: 16 } }}>
                    <Grid container flexDirection={medium ? "column" : "row"}>
                        <Grid xs={12} lg={7} sx={{ pr: { xs: 0, lg: 8 } }}>
                            <Typography align={mobile ? "center" : "left"} variant={calculateFontSize("h4")} sx={{ fontWeight: 'bold' }}>
                                Full Stack Developer specializing in{!medium && <br />} React, Python, and C#.
                            </Typography>
                            <Typography align={mobile ? "center" : "left"} variant={calculateFontSize("h6")} sx={{ mt: 3 }}>
                                I'm a dedicated full stack developer who's passionate about developing all parts of applications.
                                Having worked on all parts of the web stack, I understand the importance of scalable, optimized backend
                                code paired with eye-catching frontend design.
                            </Typography>
                        </Grid>
                        <Grid xs={12} lg={5} sx={{ pl: { xs: 0, lg: 5 } }}>
                            <Grid container justifyContent="center">
                                <Box
                                    component="img"
                                    src={ApplicationFlow}
                                    sx={{ width: { xs: '75%', sm: '50%', lg: '100%' }, height: 'auto', pt: { xs: 1, md: 0 } }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" sx={{ pb: 4, mt: { xs: 5, md: 20, lg: 10, xl: 0 }, minHeight: '700px' }}>
                <Grid item xs={11} lg={10} xl={9}>
                    <Grid container flexDirection="column">
                        <InformationSection
                            top
                            renderContent={displayInfo}
                            img={SmartPhone}
                            title='Striking, Accessable and User-Friendly Frontend Design'
                            content="I'm committed to developing UI/UX designs that keep users engaged without comprimising accessability or performance. I make sure to stay up-to-date with new technologies and trends in order to expand my understanding of software development best practices as well as to continue to develop my abilities for both personal projects and professional goals."
                        />
                        <InformationSection
                            renderContent={displayInfo}
                            img={Server}
                            title='Secure, Dependable, and Optimized Software'
                            content="Following OWASP security principles on UI/UX development and server-side authentication/authorization best practices, I've developed stable and secure payment systems that handle critical financial data as well as user authorization for handling transaction data from both an administrator and user perspective."
                            reverse
                        />
                        <InformationSection
                            renderContent={displayInfo}
                            img={Branches}
                            title='Production Ready Deployments through CI/CD Pipelines'
                            content="Using CI/CD Pipelines to build, test, format, optimize, and deploy code, I ensure that the code I write is production ready and professional during every step of the workflow. I continually look for new ways to automate and optimize the code I write to ensure the best possible product. Having experience reviewing my own and others' code, I understand the importance of clean, understandable code and I ensure that no issues goes unattended."
                        />
                    </Grid>
                </Grid>
            </Grid>

            <SkillScroller />

            <Experience type='education' />
            <Experience reverse type='work' />

            <Box sx={{ my: 6 }} />

            <Footer />
        </>
    )
}