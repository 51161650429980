import React from 'react'
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material'
import Link from '../../img/projects/link.png'
import Teaching from '../../img/projects/teaching.png'
import Robotics from '../../img/projects/robotics.png'
import Games from '../../img/projects/games.png'
import Footer from './Footer'
import { links } from '../utility/constants'


export default function Projects() {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                <Box sx={{ minHeight: '50%', mt: { xs: 8, md: 10 }, mb: { xs: 0, md: 2 }, bgcolor: '#f7f7f8', py: 1, boxShadow: "0px 1px 5px 0px rgb(0 0 0 / 14%), 0px 0px 5px 0px rgb(0 0 0 / 12%)" }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} lg={11} xl={10}>
                            <Grid container justifyContent="center">
                                {
                                    projects.map(project => (
                                        <>
                                            <Grid item xs={12} sm={10} md={4} sx={{ px: 2 }} key={project.title}>
                                                <Grid container flexDirection="column" justifyContent="space-between" sx={{ p: { xs: 2, md: 2 }, height: '100%' }}>
                                                    <Box>
                                                        <Grid container justifyContent="space-between">
                                                            <Typography variant="h5" sx={{ color: "#3e457c", fontWeight: 'bold' }}>
                                                                {project.title}
                                                            </Typography>
                                                            <IconButton onClick={() => window.open(project.link, '_blank')}>
                                                                <Box component="img" src={Link} sx={{ width: 'auto', height: 25 }} />
                                                            </IconButton>
                                                        </Grid>

                                                        <Divider sx={{ bgcolor: '#6c70ac', height: 3, borderRadius: '4px' }} />
                                                        <Typography sx={{ mt: 2 }}>{project.info}</Typography>
                                                    </Box>
                                                    <Box sx={{ height: 300, width: '100%', overflow: 'hidden', mt: 5 }}>
                                                        <Box
                                                            component="img"
                                                            src={project.img}
                                                            sx={{ width: '100%', height: 'auto' }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ))

                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Footer />
        </Box>
    )
}

const projects = [
    {
        title: 'Teaching',
        info: "In my free time, I've been teaching my fiancée Python. I've written courses using Jupyter Notebook written in markdown. One of the things I like most about teaching is experiencing the excitement of learning new material with someone else. Through teaching, I've better understood the importance of clear documentation as well as the best methods to use when learning new material for myself.",
        link: links.shortcuts,
        img: Teaching
    },
    {
        title: 'Robotics',
        info: "I'm fascinated by robotics and the idea of physically interacting with the world through software. Using OpenCV, I used face detection as authentication for a web app. I later used similar logic to develop software that would track a given object and move a camera based on its movement.",
        link: links.robotics,
        img: Robotics
    },
    {
        title: 'Games',
        info: "I enjoy creating small games that I can play with others. In fact, one of my first portfolio sites was based around a classic runner! An example of one of the games I've created is a Wordle clone that will interact with users in Discord using webhooks.",
        link: links.projectGames,
        img: Games
    },
]
