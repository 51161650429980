import React from 'react'
import { Grid, Box, Typography, useMediaQuery } from '@mui/material'
import { keyframes } from '@emotion/react'
import { skills } from '../utility/constants'
import useResponsive from '../utility/useResponsive'


export default function SkillScroller() {
    const calculateFontSize = useResponsive()
    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'))


    const listLengths = Object.keys(skills).map(k => skills[k].length)
    const scrollWidth = mobile ? 300 : 400

    return (
        <Grid container justifyContent="space-between" flexDirection="column"
            sx={{
                my: { xs: 5, md: 15 }, py: 5, bgcolor: '#f7f7f8',
                boxShadow: "0px 1px 5px 0px rgb(0 0 0 / 14%), 0px 0px 5px 0px rgb(0 0 0 / 12%)"
            }}
        >
            {
                Object.keys(skills).map((key, skillIdx) => (
                    <Grid container key={key} flexDirection="column">
                        <Box sx={{ width: '100vw' }}>
                            <Typography variant={calculateFontSize("h6")} sx={{ fontWeight: 'bold', ml: 2 }}>
                                {key}
                            </Typography>
                        </Box>
                        <Box sx={{ my: 2, overflowX: 'hidden', width: `${Math.max(...listLengths) * scrollWidth}px`, position: 'relative', display: 'flex' }}>
                            {
                                [...Array(2)].map(idx => (
                                    <Grid item sx={{ animation: `${slide} ${18 + skillIdx * 2}s linear infinite`, width: '100%' }} key={idx}>
                                        <Grid container justifyContent="space-around">
                                            {
                                                skills[key].map(skill => (
                                                    <Box>
                                                        <Grid container flexDirection="column" alignItems="center">
                                                            <Box component="img" src={skill.img} sx={{
                                                                height: { xs: '50px', xl: '75px' }, width: 'auto'
                                                            }} />
                                                            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>{skill.text}</Typography>
                                                        </Grid>
                                                    </Box>
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Box>
                    </Grid>
                ))
            }
        </Grid >
    )
}

const slide = keyframes`
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
`
