import React, { useContext } from 'react'
import { sendMessageService } from '../../../services/MessageService'
import { FeedbackContext } from '../../utility/FeedbackContext'

// This is what is imported into components using the context
export const MessageContext = React.createContext()

export default function MessageContextComponent({ children }) {
    const { handleRequestError } = useContext(FeedbackContext)

    async function sendMessage(message) {
        try {
            const response = await sendMessageService(message)
            return response
        } catch (err) {
            handleRequestError(
                err,
                "An error occurred while trying to send this message. Please try again or reach out to me through one of the links above.",
                false
            )
        }
    }


    return (
        <MessageContext.Provider value={{
            sendMessage
        }}>
            {children}
        </MessageContext.Provider>
    )
}
