import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { contacts } from '../utility/constants'
import useResponsive from '../utility/useResponsive'


export default function Footer() {
    const calculateFontSize = useResponsive()

    return (
        <Grid container justifyContent="center" sx={{
            bgcolor: 'white', minHeight: '80px', height: 'fit-content', py: 2,
            boxShadow: "0px -1px 5px 0px rgb(0 0 0 / 14%), 0px 0px 5px 0px rgb(0 0 0 / 12%)", mb: { xs: '65px', sm: 0 }
        }}>
            <Grid item xs={12} lg={11}>
                <Grid container sx={{ height: '100%', pl: { xs: 5, lg: 0 } }}>
                    {
                        contacts.map(contact => (
                            <Grid item xs={12} md={6} xl={3} key={contact.link} sx={{ cursor: contact.handleLink ? 'pointer' : 'default' }}
                                onClick={() => contact.handleLink && contact.handleLink()}>
                                <Grid container alignItems="center" sx={{ height: '100%', mt: { xs: 2, xl: 0 } }}>
                                    <Box
                                        component="img"
                                        src={contact.img}
                                        sx={{ height: { xs: '25px', md: '35px' }, width: 'auto' }}
                                    />
                                    <Box sx={{ width: '80%', ml: { xs: 1, md: 4 }, }}>
                                        <Typography
                                            variant={calculateFontSize("body2")}
                                            color="primary"
                                            sx={{ width: '100%' }}
                                        >
                                            {contact.link}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}