import React from 'react'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import CodeHighlighter from './CodeHighlighter'
import { learning, work } from './code'
import useResponsive from '../utility/useResponsive'

export default function Experience({ reverse, type }) {
    const calculateFontSize = useResponsive()
    const medium = useMediaQuery(theme => theme.breakpoints.down('lg'))

    const experienceType = {
        education: {
            title: 'Education',
            code: learning,
            paragraphs: [
                'When I first found my passion for software development I began teaching myself how to code through online courses. I quickly wanted to learn more so I joined a coding bootcamp.',
                'After spending 12-16 hours a day learning 3 full stacks over the course of 3 months, I joined a company in which I I was able to further my education and better understand working within a team pursuing the same goal.',
                'Since then, I\'ve continued to grow as a developer and I\'m constantly looking for new insights that will help me tackle complex challenges in better ways.'
            ],
            error: errors[medium ? 'medium' : 'desktop']
        },
        work: {
            title: 'Work Experience',
            code: work,
            paragraphs: [
                'Primary Developer integral in creation, review, and debugging. I worked directly with the Sr. Product Owner on the creation of new business features for a SaaS platform using React on top of Django REST Framework. I developed payment systems to handle complex peer-to-peer transactions while maintaining scalability in a multi-tiered contracting service.',
                'Following OWASP security principles on UI/UX development and prioritizing security and dependability, I deployed models to handle critical financial data.',
                'Handled automated email marketing and GA4 analytics to understand our users better and keep engagement for longer.',
                'Daemonized Celery workers to consume scheduled tasks delivered through SQS using custom Bash Scripts.'
            ]
        }
    }

    return (
        <Grid container justifyContent="center" sx={{ mt: 4 }}>
            <Grid item xs={11}>
                <Grid container flexDirection={reverse ? 'row-reverse' : 'row'}>
                    <Grid item xs={6} xl={5} sx={{ display: { xs: 'none', md: 'inline-block' } }}>
                        <Grid container justifyContent={reverse ? 'flex-end' : 'flex-start'} sx={{ px: 2 }}>
                            <CodeHighlighter language="javascript" error={experienceType[type].error} mobile={medium}>
                                {experienceType[type].code}
                            </CodeHighlighter>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} xl={7}>
                        <Grid container flexDirection="column" sx={{ px: 4, py: 4 }}>
                            <Typography variant={calculateFontSize("h4")} sx={{ fontWeight: 'bold' }}>{experienceType[type].title}</Typography>
                            {
                                experienceType[type].paragraphs.map(p => (
                                    <Typography variant={calculateFontSize("h6")} sx={{ mt: 4 }}>{p}</Typography>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}


const errors = {
    desktop: `
Traceback (most recent call last):
File "C:\\nathan-roe\\portfolio\\education.py", line 26, in
continue_to_learn
    education.continue_to_learn()
File "C:\\nathan-roe\\portfolio\\education.py", line 20, in
continue_to_learn
    super().grow()
RecursionError: maximum recursion depth exceeded while
calling a Python object`,
    medium: `
Traceback (most recent call last):
File "C:\\nathan-roe\\portfolio\\education.py",
line 26, in continue_to_learn
    education.continue_to_learn()
File "C:\\nathan-roe\\portfolio\\education.py",
line 20, in continue_to_learn
    super().grow()
RecursionError: maximum recursion depth
exceeded while calling a Python object`
}