import { useMediaQuery } from "@mui/material"

export default function useResponsive() {
    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const medium = useMediaQuery(theme => theme.breakpoints.down('lg'))

    const sizes = ["h1", "h2", "h3", "h4", "h5", "h6", "body1", "body2", "subtitle", "caption"]

    const calculateFontSize = desktop => {
        try {
            if (mobile) return sizes[sizes.findIndex(v => v === desktop) + 2]
            else if (medium) return sizes[sizes.findIndex(v => v === desktop) + 1]
            else return desktop
        } catch(_) {
            return desktop
        }
    }

    return calculateFontSize
}