import React from 'react'
import { theme } from './Theme'
import { ThemeProvider } from '@mui/material'
import App from './App'
import FeedbackContext from './components/utility/FeedbackContext'

function AppBase() {

    return (
        <ThemeProvider theme={theme}>
            <FeedbackContext>
                <App />
            </FeedbackContext>
        </ThemeProvider>
    )
}

export default AppBase