import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import ContactMeBase from '../main/contact/ContactMeBase'
import Home from '../main/Home'
import Projects from '../main/Projects'


export default function SiteRouter() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/aboutme" element={<AboutMe />} /> */}
            <Route path="/projects" element={<Projects />} />
            <Route path="/contactme" element={<ContactMeBase />} />
            <Route path="*" element={<Navigate to='/' replace />} />
        </Routes>
    )
}