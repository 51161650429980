export const initiateGA4 = () => {
    var head = document.getElementsByTagName('head')[0]
    var script = document.createElement('script')
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS}`
    script.async = true
    head.appendChild(script)
    // Configure gtag and send page_view of the loaded page's location
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS, {
      page_path: window.location.pathname + window.location.search
    })
}