import React, { useState } from 'react'

// This is what is imported into components using the context
export const FeedbackContext = React.createContext()

const ERROR_TIMEOUT = 3000

export default function FeedbackContextComponent({ children }) {
    const [errorMessage, setErrorMessage] = useState('')

    // Update the error message on request error
    async function handleRequestError(err, message, addTimeout = true) {

        if (err?.response) {
            switch (err.response.status) {
                case 422: // Unable to process request
                    setErrorMessage(err.response.data.message)
                    break
                case 429: // Max requests made (5/day)
                    setErrorMessage('It looks like you\'ve already sent quite a few message. Feel free to send me a message through on of the links provided!')
                    break
                default:
                    setErrorMessage(message)
                    break
            }
        } else if (err?.request) {
            setErrorMessage('There was an error connecting with the server. Please try again later.')
        } else {
            setErrorMessage(message)
        }
        addTimeout && setTimeout(() => { setErrorMessage('') }, ERROR_TIMEOUT)
    }


    return (
        <FeedbackContext.Provider value={{
            errorMessage,
            setErrorMessage,
            handleRequestError
        }}>
            {children}
        </FeedbackContext.Provider>
    )
}
