import React from 'react' // , { useContext, useMemo, useState }
import { Box, Grid, Typography } from '@mui/material' //  Button, TextField, useMediaQuery
// import { validEmail, validName } from '../../utility/validations'
import { contacts } from '../../utility/constants'
import Portrait from '../../../img/home/contactme/portrait.jpg'
import useResponsive from '../../utility/useResponsive'
// import { MessageContext } from './MessageContext'
// import { FeedbackContext } from '../../utility/FeedbackContext'
// import PhoneNumberInput from './PhoneNumberInput'

export default function ContactMe() {
    const calculateFontSize = useResponsive()
    // const phone = useMediaQuery(theme => theme.breakpoints.down('sm'))
    // const { sendMessage } = useContext(MessageContext)
    // const { errorMessage, setErrorMessage } = useContext(FeedbackContext)

    // const [formData, setFormData] = useState(defaultFormData)

    // const handleSubmit = async (e) => {
    //     e.preventDefault()
    //     const res = await sendMessage(formData)
    //     res && setFormData(defaultFormData)
    // }

    // const handleChange = (e, key, isEvent = true) => {
    //     const { value } = isEvent ? e.target : { value: e }
    //     setFormData(f => ({ ...f, [key]: value }))
    //     errorMessage && setErrorMessage(false)
    // }

    // const error = useMemo(() => {
    //     const { name, company, email } = formData
    //     return (
    //         [name?.length, company?.length].some(v => v.length < 3)
    //         || !validEmail(email)
    //         || !validName(name)
    //     )
    // }, [formData])

    return (
        <Grid container justifyContent="center" sx={{ mb: '65px' }}>
            <Grid item xs={10}>
                <Grid container sx={{ mt: { xs: 10, md: 20 } }} alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={8}>
                        <Typography variant={calculateFontSize("h5")}>
                            I'm dedicated to bringing software solutions to life through engaging UI/UX design and comprehensive
                            backend development. If you're looking to better understand my experience
                            and abilities as a full stack developer, reach out to me through LinkedIn, email, or phone through the
                            links provided. If you're interested in my projects, feel free to view them through my Github page or
                            ask me about them! Also, feel free to leave me a message through the form below and I'll make sure
                            to get back to you.
                        </Typography>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Grid container justifyContent="center">
                            <Box sx={{ height: { xs: 200, lg: 300 }, width: { xs: 200, lg: 300 }, borderRadius: '50%', overflow: 'hidden' }}>
                                <Box
                                    component="img"
                                    src={Portrait}
                                    sx={{ width: '100%', height: 'auto', borderRadius: '50%', transform: 'translateY(-15%)' }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" sx={{ mt: { xs: 10, md: 15 } }}>
                    {
                        contacts.map(contact => (
                            <Grid item xs={12} md={6} sx={{ py: 2, cursor: 'pointer' }} key={contact.link}
                                onClick={() => contact.handleLink && contact.handleLink()}>
                                <Grid container alignItems="center">
                                    <Box
                                        component="img"
                                        src={contact.img}
                                        sx={{ width: 'auto', height: { xs: 35, md: 50 } }}
                                    />
                                    <Typography variant={calculateFontSize("h6")} sx={{ ml: 5 }}>{contact.link}</Typography>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
                {/* <Grid container sx={{ mt: { xs: 10, md: 20 } }}>
                    <Grid item xs={12} md={8}>
                        <Typography variant="body1">
                            Thank you for spending the time to look at my site! Leave me a message using this form and I'll make sure
                            to get back to you. If you have any questions or want to start a conversation feel free to use this or any of
                            the links above to contact me directly.
                        </Typography>
                    </Grid>
                </Grid> */}
            </Grid>
        </Grid>
    )
}


const defaultFormData = {
    'name': '',
    'company': '',
    'email': '',
    'phone': '',
    'message': ''
}


// {
//     <form onSubmit={handleSubmit}>
//         <Grid container sx={{ mb: 5, mt: 1 }} spacing={2}>
//             <Grid item xs={12} md={5}>
//                 <Grid container spacing={2}>
//                     <Grid item xs={12}>
//                         <TextField
//                             fullWidth
//                             size={phone ? "small" : "medium"}
//                             label="Name"
//                             value={formData.name}
//                             onChange={e => handleChange(e, 'name')}
//                         />
//                     </Grid>
//                     <Grid item xs={12}>
//                         <TextField
//                             fullWidth
//                             size={phone ? "small" : "medium"}
//                             label="Email"
//                             value={formData.email}
//                             onChange={e => handleChange(e, 'email')}
//                         />
//                     </Grid>
//                     <Grid item xs={12}>
//                         <PhoneNumberInput
//                             phoneNumber={formData.phone}
//                             onChange={val => handleChange(val, 'phone', false)}
//                             handleBlur={country => console.log(country, formData.phone)}
//                         />
//                         {/* <TextField
//                     fullWidth
//                     size={phone ? "small" : "medium"}
//                     label='Phone (Optional)'
//                     value={formData.phone}
//                     onChange={e => handleChange(e, 'phone')}
//                 /> */}
//                     </Grid>
//                     <Grid item xs={12}>
//                         <TextField
//                             fullWidth
//                             size={phone ? "small" : "medium"}
//                             label='Company (Optional)'
//                             value={formData.company}
//                             onChange={e => handleChange(e, 'company')}
//                         />
//                     </Grid>
//                 </Grid>
//             </Grid>
//             <Grid item xs={12} md={7}>
//                 <TextField
//                     fullWidth
//                     size={phone ? "small" : "medium"}
//                     multiline
//                     rows={10.4}
//                     label="Message"
//                     value={formData.message}
//                     onChange={e => handleChange(e, 'message')}
//                 />
//             </Grid>
//             <Grid container justifyContent={errorMessage ? 'space-between' : "flex-end"}>
//                 {
//                     errorMessage &&
//                     <Grid item xs={6} sx={{ pl: 3, mt: 2 }}>
//                         <Typography variant="body1" color="error">{errorMessage}</Typography>
//                     </Grid>
//                 }
//                 <Grid item xs={6} md={3} sx={{ mt: { xs: 2, md: 5 } }}>
//                     <Button variant="contained" fullWidth type="submit" disabled={error}>
//                         <Typography variant="body1" sx={{ fontWeight: 'bold', my: { xs: .25, md: .5 } }}>Send</Typography>
//                     </Button>
//                 </Grid>
//             </Grid>
//         </Grid>
//     </form>
// }