import React, { useCallback, useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { Grid, Typography, Collapse, useMediaQuery, Tabs, Tab } from '@mui/material'
import Resume from '../../img/home/Nathan Roe - Resume.pdf'
import { Link, useLocation } from 'react-router-dom'


export default function Header() {
  const location = useLocation()
  const [headerStyle, setHeaderStyle] = useState({})
  const [page, setPage] = useState()
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const headers = [
    // {
    //   'title': 'About Me',
    //   'link': '/aboutme',
    //   'offset': 100
    // },
    {
      'title': 'Projects',
      'link': '/projects',
      'offset': 100
    },
    {
      'title': 'Contact Me',
      'link': '/contactme'
    }
  ]

  const handleScroll = useCallback(() => {
    if (window.scrollY > 0) {
      setHeaderStyle(hs => ({
        ...hs,
        bgcolor: 'white',
        color: 'primary.main',
        logo: 'primary.main',
        active: true
      }))
    } else if (location.pathname === '/') {
      setHeaderStyle(hs => ({
        ...hs,
        bgcolor: 'transparent',
        color: 'rgba(255,255,255,.75)',
        logo: 'white',
        active: false
      }))
    } else {
      setHeaderStyle(hs => ({
        ...hs,
        bgcolor: 'primary.main',
        color: 'white',
        logo: 'white',
        active: false
      }))
    }
  }, [location.pathname])

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
  }, [handleScroll])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (location.pathname !== '/') {
      setHeaderStyle(hs => ({
        ...hs,
        bgcolor: 'primary.main',
        color: 'white',
        logo: 'white',
        active: false
      }))
    } else {
      handleScroll()
    }
  }, [location, handleScroll])


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{
        height: '65px', paddingX: { xs: 0, md: '17px' }, display: 'flex', alignItems: 'center',
        background: headerStyle?.bgcolor || 'transparent',
        transition: 'background .5s'
      }}>
        <Toolbar sx={{ maxWidth: '1980px', width: '100%', height: '100%' }}>
          <Grid container sx={{ pl: { xs: 2, md: 5 }, pr: { xs: 2, md: 10 } }} justifyContent="space-between" alignItems="center">
            <Box sx={{ flexGrow: 1, '& *': { textDecoration: 'none' } }}>
              <Link to={'/'} onClick={() => setPage()}>
                <Typography variant="h6"
                  sx={{ fontWeight: 'bold', color: headerStyle?.logo || 'white', display: 'flex', alignItems: 'flex-end', cursor: 'pointer' }}
                  onClick={e => {
                    if (location.pathname === '/') {
                      e.preventDefault()
                      window.scrollTo(0, 0)
                    }
                  }}
                >
                  {'<N'}
                  <Collapse in={headerStyle?.active} sx={{ display: 'inline-block' }} orientation="horizontal" timeout={600}>athan&nbsp;</Collapse>
                  {'R'}
                  <Collapse in={headerStyle?.active} sx={{ display: 'inline-block' }} orientation="horizontal" timeout={600}>oe</Collapse>
                  {'/>'}
                </Typography>
              </Link>
            </Box>
            <Box sx={{ flexGrow: 8 }} />
            <Box sx={{ flexGrow: { xs: 0, sm: 1 }, display: 'flex', justifyContent: 'space-between', alignItems: 'center', '& *': { textDecoration: 'none' } }}>

              <Tabs
                value={page}
                onChange={(_, val) => setPage(val)}
                indicatorColor="secondary"
                sx={{ padding: 0, width: '100%', '& .MuiTabs-fixed': { '& .MuiTabs-flexContainer': { justifyContent: 'space-between' } } }}
              >
                <Tab sx={{ padding: 0, border: 'none' }} label={
                  <Box component="a" href={Resume} target="_blank" sx={{ textDecoration: 'none' }}>
                    <Typography variant="body1" sx={{
                      color: headerStyle?.color || 'rgba(255,255,255,.75)',
                      fontWeight: 'bold',
                      textTransform: 'none'
                    }}>
                      Resume
                    </Typography>
                  </Box>
                } />
                {
                  !mobile && headers.map(h => (
                    <Tab sx={{ padding: 0 }} value={h.title} label={
                      <Link to={h.link} style={{ cursor: 'pointer', height: '50px', width: '100%' }}>
                        <Grid container alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
                          <Typography variant="body1" sx={{
                            color: headerStyle?.color || 'rgba(255,255,255,.75)',
                            fontWeight: 'bold',
                            textTransform: 'none'
                          }}>
                            {h.title}
                          </Typography>
                        </Grid>
                      </Link>
                    } />
                  ))
                }
              </Tabs>
            </Box>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  )
}